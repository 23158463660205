<template>
    <v-col v-if="showCard" cols="12" sm="6" lg="4">
        <v-card class="secondary lighten-1" width="100%" dark>
            <v-card-title class="mx-auto">
                <v-icon large left>mdi-human-greeting</v-icon>Welcome!
            </v-card-title>
            <v-card-text>
                <div>Hello and welcome to BlitzIt Web.  We hope you enjoy your experience here.  Thought you might like a few options to start with:</div>
                <v-list-item v-if="subscription == 'SUPP'" :to="{ name: 'supplier-dashboard' }">
                    <v-list-item-icon>
                        <v-icon>mdi-view-dashboard</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title class="primary--text">Go To Dashboard</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="subscription == 'COUR'" :to="{ name: 'courier-dashboard' }">
                    <v-list-item-icon>
                        <v-icon>mdi-view-dashboard</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title class="primary--text">Go To Dashboard</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="subscription == 'CUST' || subscription == 'CUSTFREE'" :to="{ name: 'customer-dashboard' }">
                    <v-list-item-icon>
                        <v-icon>mdi-view-dashboard</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title class="primary--text">Go To Dashboard</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item :to="{ name: 'purchase-order', params: { id: 'new' } }">
                    <v-list-item-icon>
                        <v-icon>{{ $BlitzIt.navigation.findIcon('purchase-orders') }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title class="primary--text">Create An Order</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="$BlitzIt.auth.session.canInstallApp" @click="$BlitzIt.auth.session.installPrompt.prompt()">
                    <v-list-item-icon>
                        <v-icon>mdi-download</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title class="primary--text">Download The App</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

            </v-card-text>
            <!-- <v-card-actions>
                <v-btn class="primary--text" text :to="{ name: 'setup' }">Get Started!</v-btn>
                <v-spacer />
            </v-card-actions> -->
        </v-card>
    </v-col>
</template>

<script>
export default {
    name: 'Welcome-Card',
    data: function() {
        return {
            showCard: true,
        }
    },
    computed: {
        subscription() {
            return this.$BlitzIt.auth.session?.subscriptionCode;
        },
    },
    async created() {
        //prompt to install app if not installed
        // window.addEventListener('beforeinstallprompt', e => {
        //     e.preventDefault();

        //     this.deferredPrompt = e;
        //     this.showInstallButton = true;
        // })
    },
    mounted() {
        //var ageCookie = this.$BlitzIt.auth.getCookie('welcome');
        this.$BlitzIt.auth.removeCookie('welcome');
        var ageCookie = this.getLocally('welcome');
        if (ageCookie == null) {
            //first time so leave a cookie
            ageCookie = this.$BlitzIt.auth.createUTC();
            this.saveLocally('welcome', ageCookie);
            // this.$BlitzIt.auth.saveCookie('welcome', ageCookie);
        }

        var cookieDate = this.$BlitzIt.auth.formUTCDateTime(ageCookie);
        var nowDate = this.$BlitzIt.auth.createRawUTC().plus({ days: -7 });

        this.showCard = nowDate < cookieDate;
    },
    methods: {
        installApp() {
            if (this.$BlitzIt.auth.session.installPrompt != null) {
                this.$BlitzIt.auth.session.installPrompt.prompt();
            }
            // if (this.deferredPrompt != null) {
            //     this.deferredPrompt.prompt();
            // }
        },
    }
}
</script>